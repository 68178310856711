@import './scss/fonts.scss';
@import './scss/menu.scss';
@import './scss/layout/layout.scss';

html, body {
  height: 100vh;
  width: 100vw;
}
html {
  background-color: #fff;
  font-size: 18px;

  body {
    min-height: 100vh;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-style: normal;
    position: relative;
  }
}

.background-logo {
  position: fixed;
  width: 43%;
  height: 100%;
  left: -178px;
  background: url('./assets/img/ff-logo.jpg') no-repeat;
  background-size: contain;
  z-index: -1;
  opacity: 0.2;
}
* {
  box-sizing: border-box;
}

img {
  width: 100%;
}

.App {
  text-align: center;
  max-width: 100vw;
  position: relative;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
}

.content-container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 1rem;
}

.video-iframe {
  margin-top: 1rem;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.App-header {
  display: flex;
  align-items: center;
  // font-family: 'Futura PT DemiBold';
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-style: normal;

  &--left,
  &--right {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    @media (min-width: 767px) {
      >div {
        padding: 5px;
      }
    }

    >div:first-child {
      border-bottom: 3px solid #000;
    }

  }


  &--right {
    text-align: right;
    font-size: 1.1rem;
    span {
      font-size: 0.7rem;
    }
  }

  .App-logo {
    width: 200px;
    margin: 0 auto;
  }
  
}

.banner {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: "futura-pt", sans-serif;
  font-weight: 600;
  font-style: normal;

  margin-top: 2rem;
  font-size: 3rem;
  @media (min-width: 767px) {
    font-size: 7vw;
  }

  span:first-child {
    font-size: 1.2rem;
  }
}

.background-pinstripe {
  z-index: -1;
  position: absolute;
  left: -50%;
  top: 90vh;
  width: 200%;

  .container {
    transform: rotate(-45deg);
    background-color: red;
    padding: 5px 0;
    width: 100%;
    display: block;
  }

  .black {
    background-color: #000;
    height: 10vh;
    width: 200vw;
    padding: 5px 0;
    display: block;
  }

  .white {
    background-color: #fff;
    padding: 5px 0;
    height: 100%;
    width: 100%;
    display: block;
  }
}

.paragraph {
  line-height: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: block;

  &--content {
    background-color:#ffffffe3;
    padding: 1.5rem;
  }

  &--small {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;

  }

  &--medium {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.banner-img {
  width: 100%;
  display: block;
  @media only screen and (min-width: 768px) {
    height: 50vh;
  }

  img {
    object-fit: cover;
    width: 100vw;
    @media only screen and (min-width: 768px) {
      height: 100%;
    }
  }
}

h2 {
  text-align: center;
}

h3 {
  font-weight: normal;
}

a {
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.tour-list {
  .tour-row {
    width: 100%;
    display: flex;

    align-items: center;
    text-decoration: none;
    flex-direction: column;
    @media only screen and (min-width: 550px) {
      flex-direction: row;
      justify-content: space-between;
    }

    &.past {
      text-decoration: line-through;
    }

    &:not(:first-child) {
      margin-top: 2rem;
    }
    .tour-date,
    .tour-data {

      display:flex;
      flex-direction: column;
      justify-content: center;
      font-weight: normal;
      //width: 100%;
      @media only screen and (min-width: 550px) {
        width: 50%;
      }
    }

    .tour-data {
      text-align: center;
      padding: 1rem 2rem;
      @media only screen and (min-width: 550px) {
        text-align: left;
        padding: 0 2rem;
      }

    }

    .tour-date {
      font-size: 1.5rem;
      text-align: center;
      padding: 1rem 2rem;
      border-bottom: 1px solid #000;

      @media only screen and (min-width: 550px) {
        border-right: 1px solid #000;
        border-bottom: 0;
        text-align: right;
      }
    }

    .tour-name {
      font-weight: 500;
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    .tour-location {
      font-style: italic;
    }
  }
}

.hide-on-mobile {
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
}

.divider {
  display: block;
  width: 100%;
  height: 20px;

  background: url('./assets/img/lightning.png') no-repeat center;
  background-size: contain;
}

.hover-effect {
  position: absolute;
  background-color: #fff;
  transform: rotate(-45deg);
  width: 480px;
  height: 800px;
  left: -500px;
  top: 50%;
}

.liveImage {
  position: relative;
  width: 300px;
  height: 450px;
  margin: 0 auto;
  overflow: hidden;

  .live {
    display: none;
    position: absolute;
    top: 0;

    img {
      width:300px;
      height: 450px;
    }

    &.my-node-enter {
      display: block;
      opacity: 0;
      +div.hover-effect {
        left: -500px;
        transition: all 0.4s ease-out;
      }
    }
    &.my-node-enter-active {
      display: block;
      opacity: 1;
      transition: opacity 200ms;
      
      +div.hover-effect {
        left: 350px;
        top: -130%;
        transition: all 0.4s ease-out;
        background-color:rgb(255, 255, 255);
      }
    }
    &.my-node-enter-done {
      display: block;
      +div.hover-effect {
        left: 350px;
        top: -130%;
        transition: all 0.4s ease-out;
        background-color:rgb(255, 255, 255);
      }
    }

    &.my-node-exit {
      display: block;
      opacity: 1;
      +div.hover-effect {
        left: -500px;
        transition: all 0.4s ease-out;
      }
    }

    &.my-node-exit-active {
      opacity: 0;
      transition: opacity 200ms;

      +div.hover-effect {
        left: -500px;
        transition: all 1.4s ease-out;
      }
      
    }
    &.my-node-exit-done {
      display: none;
    }
  }

  
  img {
    object-fit: cover;
    height: 100%;
  }

}

.member-card {
  &:not(:first-child) {
    margin-top: 4rem;
  }
}