.menu {
    font-size: 0.8rem;
    // font-family: "Futura PT Medium";
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-style: normal;
}
.menu-toggle {
    display: block;
    display: flex;
    flex-direction: column;
    position: fixed;
    cursor: pointer;
    z-index: 10001;
    max-width: 36px;
    width: 36px;
    right: 3rem;
    top: 3rem;
    height: 30px;
    justify-content: space-between;
    @media (min-width: 767px) {
        display: none;
    }

    span {
        height: 9px;
        width: 100%;
        transition: opacity 0.4s, transform 0.4s;
      }

      span:nth-of-type(1) {
        background: url(../assets/img/burger-1-black.png) no-repeat top;
        background-size: contain;
      }
      span:nth-of-type(2) {
        background: url(../assets/img/burger-2-black.png) no-repeat top;
        background-size: contain;
        transform: rotate(1deg)
      }
      span:nth-of-type(3) {
        background: url(../assets/img/burger-3-black.png) no-repeat top;
        background-size: contain;
        transform: rotate(-2deg)
      }
  
      &.active {
        span:nth-of-type(1) {
          transform: rotate(45deg) translateX(-1px) translateY(7px);
        }
        span:nth-of-type(2) {
          opacity: 0;
        }
        span:nth-of-type(3) {
          transform: rotate(-45deg) translate(4px, -9px);
        }
      }
}

@media only screen and (min-width: 768px) {
    .mobile-menu {
        display: none;
    }
}

.mobile-menu {
    font-size: 0.9rem;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-style: normal;
    position: fixed;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 10000;
    padding: 4rem 3rem 1rem 1rem;
    text-transform: uppercase;
    text-align: right;
    right: 100vw;
    opacity: 0;
    transition: all 0.4s;
  
    &.active {
      right: 0;
      opacity: 1;
    }
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        width: 100%;
        margin-top: 2rem;
  
        a {
          font-size: 3.5rem;
          // @media (min-width: 450px) {
          //   font-size: 4rem;
          // }
          transition: color 0.4s;
          color: #000;
        }
      }
    }
  }
  